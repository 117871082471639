import { gql } from "@apollo/client";

export const CURRENT_ADMIN_USER = gql`
  query currentAdminUser {
    currentAdminUser {
      id
      email
      firstName
      lastName
    }
  }
`;

