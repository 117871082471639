import React from "react";

import { Center, Spinner } from "@chakra-ui/react";

const LoadingScreen = ({ height }) => {
  return (
    <Center textAlign="center" fontSize="xl" h={height ? height : "100vh"}>
      <Spinner size="xl" />
    </Center>
  );
};

export default LoadingScreen;
