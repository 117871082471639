 
import {
  Box,
  Flex,
  // Avatar,
  // Text,
  Stack,
} from "@chakra-ui/react";

//   Custom components
// import SidebarCard from "components/sidebar/components/SidebarCard";
import Brand from "./Brand";
import Links from "./Links";
import React from "react";
// import avatar4 from "assets/img/avatars/avatar4.png";

// FUNCTIONS

function SidebarContent(props) {
  const { routes } = props;
  // const textColor = useColorModeValue("navy.700", "white");

  return (
    <Flex
      direction="column"
      minH="100%"
      height="max-content"
      // pt="20px"
      borderRadius="30px"
    >
      {/* <Brand /> */}
      <Stack direction="column" mb="auto" mt="100px">
        <Box ps="20px" >
          <Links routes={routes} />
        </Box>
      </Stack>
    </Flex>
  );
}

export default SidebarContent;
