import { mode } from "@chakra-ui/theme-tools";
export const globalStyles = {
  colors: {
    brand: {
      50: "#d9fdff",
      100: "#adf1ff",
      200: "#7fe7fb",
      300: "#50dcf8",
      400: "#24d2f5",
      500: "#0ab9db",
      600: "#0090ab",
      700: "#00677c",
      800: "#003f4c",
      900: "#00171c",
    },
    brandScheme: {
      50: "#d9fdff",
      100: "#adf1ff",
      200: "#7fe7fb",
      300: "#50dcf8",
      400: "#24d2f5",
      500: "#0ab9db",
      600: "#0090ab",
      700: "#00677c",
      800: "#003f4c",
      900: "#00171c",
    },
    brandTabs: {
      50: "#d9fdff",
      100: "#adf1ff",
      200: "#7fe7fb",
      300: "#50dcf8",
      400: "#24d2f5",
      500: "#0ab9db",
      600: "#0090ab",
      700: "#00677c",
      800: "#003f4c",
      900: "#00171c",
    },
    secondaryGray: {
      100: "#E0E5F2",
      200: "#E1E9F8",
      300: "#F4F7FE",
      400: "#E9EDF7",
      500: "#8F9BBA",
      600: "#A3AED0",
      700: "#707EAE",
      800: "#707EAE",
      900: "#1B2559",
    },
    red: {
      100: "#FEEFEE",
      500: "#EE5D50",
      600: "#E31A1A",
    },
    blue: {
      50: "#EFF4FB",
      500: "#3965FF",
    },
    orange: {
      100: "#FFF6DA",
      500: "#FFB547",
    },
    green: {
      100: "#E6FAF5",
      500: "#01B574",
      600: "#43A047",
    },
    navy: {
      50: "#d0dcfb",
      100: "#aac0fe",
      200: "#a3b9f8",
      300: "#728fea",
      400: "#3652ba",
      500: "#1b3bbb",
      600: "#24388a",
      700: "#2C2C2C",
      800: "#1E1E1E",
      900: "#1A1A1A"
    },
    dark: {
      700: "#2C2C2C",
      800: "#1E1E1E",
      900: "#1A1A1A"
    },
    gray: {
      100: "#FAFCFE",
    },
    primaryBackgroundLight: "#F9F9F9",
    primaryBackgroundDark: "#1A1A1A",
    primaryLight: {
      25: "#FFFFFF",
      50: "#F9F9F9",
      100: "#F5F5F5",
      200: "#ECECEC",
      300: "#7F7F7F",
      400: "#787878", 
      500: "#5E5E5E",
      600: "#454545",
      700: "#2F2F2F",
      800: "#242424",
      900: "#191919",
    },
    primaryDark: {
      25: "#F8F8F8",
      50: "#EAEAEA",
      100: "#D3D3D3",
      200: "#ADADAD",
      300: "#949494",
      400: "#787878",
      500: "#5E5E5E",
      600: "#454545",
      700: "#2F2F2F",
      800: "#242424",
      900: "#191919",
    },
    textLight: {
      main: "#323B4A",
      50: "#9B9B9B",
      100: "#838992",
    },
    textDark: {
      main: "#ECEDEF",
      25: "#E8E9EB",
      50: "#878D96",
      100: "#838992",
    },
    lightDark: {
      25: "#D9DBDD",
    },
    borderDark: {
      25: "#5B5D63",
      50: "#35363A",
      100: "#2E2E2E"
    },
    white: {
      100: "#FFFFFF",
      200: "#F9F9F9",
      300: "#FBFBFB",
    },
    dark: {
      100: "#1A1A1A", // Main backgrounds and such
      200: "#1C1C1C", // Sidebar / Header
      300: "#353539", // Sidebar / Header border
      400: "#434449", // Dark background inside overlays
      500: "#505457", // Overlays
    },
    lightModeBg: {
      canvasToolbar: "#ECECEC",
      canvasBody: "#F9F9F9",
    },
    darkModeBg: {
      canvasToolbar: "#1C1C1C",
      canvasBody: "#252525",
    },
  },
  // fontSizes: {
  //   xs: "0.75rem",
  //   sm: "1rem",
  //   md: "1.125rem",
  //   lg: "1.25rem",
  //   xl: "1.25rem",
  //   "2xl": "1.5rem",
  //   "3xl": "1.875rem",
  //   "4xl": "2.25rem",
  //   "5xl": "3rem",
  //   "6xl": "3.75rem",
  //   "7xl": "4.5rem",
  //   "8xl": "6rem",
  //   "9xl": "8rem",
  // },
  styles: {
    global: (props) => ({
      body: {
        overflowX: "hidden",
        bg: mode("#FBFBFB", "navy.900")(props),
        fontFamily: "DM Sans",
      },
      input: {
        color: "gray.700",
      },
      html: {
        fontFamily: "DM Sans",
      },

      "&::-webkit-scrollbar": {
        width: "4px",
        height: "4px",
        borderRadius: "8px",
        backgroundColor: `rgba(0, 0, 0, 0.05)`,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: globalStyles.colors.brand[300],
      },
      ".ag-header-cell-text": {
        color: mode("#A3AED0", "white")(props),
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: "500",
      },
      ".ag-header-cell": {
        backgroundColor: mode("white", "navy.800")(props),
      },
      ".ag-cell": {
        backgroundColor: mode("transparent", "navy.800")(props),
      },
      ".ag-cell:hover": {
        backgroundColor: mode("transparent", "#535E82")(props),
      },
      ".ag-row": {
        color: mode("#2B3674 !important", "white !important")(props),
        fontWeight: "700",
        fontSize: "14px",
        fontFamily: "DM Sans",
        padding: "0px",
      },
      ".ag-center-cols-viewport": {
        overflowX: "hidden !important",
      },
      ".fc": {
        /* the calendar root */ 
        // maxHeight: "90%",
        // margin: "0 auto",
      },
      ".fc-button-primary": {
        backgroundColor: `${globalStyles.colors.secondaryGray[300]} !important`,
        borderColor: `${globalStyles.colors.secondaryGray[300]} !important`,
        color: `${globalStyles.colors.brand[500]} !important`,
        borderRadius: "8px !important",
        /* the calendar root */ 
        // maxHeight: "90%",
        // margin: "0 auto",
      },
      ".fc-prev-button": {
        marginRight: "10px !important"
      },
      ".fc-scrollgrid": {
        borderRadius: "8px !important",
      },
      ".fc-col-header-cell-cushion": {
        fontWeight: "700 !important",
        fontSize: "14px !important",
        fontFamily: "DM Sans !important",
        color: mode("#2B3674 !important", "white !important")(props),
      },
      ".fc-daygrid-day-number": {
        fontWeight: "500 !important",
        fontSize: "14px !important",
        fontFamily: "DM Sans !important",
        paddingRight: "10px !important",
        color: mode("#2B3674 !important", "white !important")(props),
      }
    }),
  },
};
