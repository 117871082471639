import client from "common/graphql/MyApolloClient";
import { CURRENT_ADMIN_USER } from "common/graphql/user";
import { createSlice } from "@reduxjs/toolkit";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: true,
  errors: null,
  currentAdminUser: null,
};

const slice = createSlice({
  name: "currentAdminUser",
  initialState,
  reducers: {
    setCurrentAdminUser(state, action) {
      state.isLoading = false;
      state.error = null;
      state.currentAdminUser = action.payload;
    },
    setFailedCurrentAdminUserLoad(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.currentAdminUser = null;
    },

    updatePartnerAccountForUser(state, action) {
      state.isLoading = false;
      state.error = null;

      state.currentAdminUser = {
        ...state.currentAdminUser,
        partnerAccount: action.payload,
      };
    },

    logOutUser(state) {
      state.isLoading = true;
      state.error = null;
      state.currentAdminUser = null;
    },
  },
});

// Reducer
export default slice.reducer;
export const { logOutUser, updatePartnerAccountForUser } = slice.actions;

export function getCurrentAdminUser(name) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    console.log("getCurrentAdminUser");
    client
      .query({
        query: CURRENT_ADMIN_USER,
        variables: {},
        fetchPolicy: "network-only",
      })
      .then(({ errors, data }) => {
        if (errors) {
          dispatch(
            slice.actions.setFailedCurrentAdminUserLoad("Failed to load current user")
          );
          console.log("Errors during CURRENT_ADMIN_USER", errors);
          return;
        }

        console.log("currentAdminUserDATA", data);
        if (
          data &&
          data.currentAdminUser &&
          data.currentAdminUser.id
        ) {
          dispatch(slice.actions.setCurrentAdminUser(data.currentAdminUser));
        } else {
          dispatch(
            slice.actions.setFailedCurrentAdminUserLoad("Failed to load current admin user")
          );
        }
      });
  };
}
