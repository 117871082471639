import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentAdminUser } from "redux/slices/currentAdminUserrReducer";
import { Redirect, useLocation } from "react-router-dom";
import LoadingScreen from "components/feedback/LoadingScreen";
import { PATH_PAGE, PATH_APP } from "routes/paths";

const CurrentPartnerAccountGuard = ({ children }) => {
  const dispatch = useDispatch();
  const { isLoading, errors, currentAdminUser } = useSelector(
    (state) => state.currentAdminUser
  );

  useEffect(() => {
    dispatch(getCurrentAdminUser());
  }, [dispatch]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (errors || !currentAdminUser || !currentAdminUser.id) {
    console.log("ERROR occurred in CurrentPartnerAccountGuard: ", errors);
    return <Redirect to={PATH_PAGE.error500} />;
  }

  return children;
}

export default CurrentPartnerAccountGuard;
