// import NProgress from 'nprogress';
import AppRoutes from "./AppRoutes";
import { PATH_PAGE, PATH_APP } from "./paths";
import LoadingScreen from "components/feedback/LoadingScreen";
import GuestProtect from "components/auth/GuestProtect";
import { Switch, Route, Redirect } from "react-router-dom";
import React, { Suspense, Fragment, lazy, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import CurrentAdminUserGuard from "components/auth/CurrentAdminUserGuard";
import AuthProtect from "components/auth/AuthProtect";
// import CalandarDashboard from "layouts/adminCalandarLayout";
import Dashboard from "layouts/admin";


export const Routes = ({ routes = [] }) => {
  useHistoryChange();
  return renderRoutesInner(routes);
};

function useHistoryChange() {
  const history = useHistory();
  const prevLocation = useRef(history.location);

  useEffect(() => {
    const unlisten = history.listen((location) => {
      const sourcePage = prevLocation.current;
      const destinationPage = location;

      // TODO: Find a nice way to store different redirect logic
      const leavingDetailPage = sourcePage?.pathname?.match(/\/purchasing\/purchaseorders\/details\/\d+/);
      const goingToPurchasePage = destinationPage?.pathname === "/purchasing";
      const leftUsingPORedirect = sourcePage?.search?.includes("viewPO=1");
      const shouldLoadPOTab = leavingDetailPage && goingToPurchasePage && leftUsingPORedirect;
      prevLocation.current = location;

      if (shouldLoadPOTab) {
        history.push({
          pathname:
            PATH_APP.purchasing.root,
          search: "viewPO=1"
        })
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);
}


const renderRoutesInner = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Layout = route.layout || Fragment;
        const Component = route.component;
        const Guard = route.guard || Fragment;
        const CurrentAdminUserGuardComponent= route.currentAdminUserGuard
          ? CurrentAdminUserGuard
          : Fragment;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <CurrentAdminUserGuardComponent>
                  <Layout>
                    {route.routes ? (
                      renderRoutesInner(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </CurrentAdminUserGuardComponent>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  // Others Routes
  {
    exact: true,
    guard: GuestProtect,
    path: "/login",
    component: lazy(() => import("views/auth/signIn/SignInDefault")),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("views/errors/Page404View")),
  },
  {
    exact: true,
    path: "/500",
    component: lazy(() => import("views/errors/Page500View")),
  },

  {
    exact: true,
    path: PATH_PAGE.auth.root,
    component: () => <Redirect to={PATH_PAGE.auth.login} />,
  },


  // App Routes
  AppRoutes,
];

export default routes;
